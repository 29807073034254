import { Accordion, Card } from "react-bootstrap";

function DisclaimerAccordion() {
  return (
    <Accordion bg='success' style={{minWidth: '200px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Important Notice</Accordion.Header>
        <Accordion.Body>
          <Card>
            <Card.Body>
              <Card.Title>Disclaimer</Card.Title>
              <Card.Text>
                The projections presented are based on 2017 prices and market
                expectations. Since then, the market for tropical hardwoods has
                experienced significant changes, with the value approximately
                doubling due to several key factors:
              </Card.Text>
              <ul>
                <li>
                  <strong>Increased Fragmentation in Global Trade:</strong>{" "}
                  Restrictions and barriers between economic blocs have limited
                  the free flow of natural resources, increasing scarcity and
                  driving up prices.
                </li>
                <li>
                  <strong>
                    Protection Measures for Tropical Hardwood Species:
                  </strong>{" "}
                  Conservation efforts, including the US Endangered Species Act
                  of 1973 and international agreements like CITES, have further
                  constrained supply, particularly for endangered or vulnerable
                  species such as Mahogany, Rosewood, and Spanish Cedar.
                </li>
                <li>
                  <strong>Growing Demand for Renewable Resources:</strong>{" "}
                  Rising environmental awareness and shifts toward sustainable
                  materials have made tropical hardwoods, especially those
                  sourced through regenerative agroforestry practices, more
                  desirable in premium markets.
                </li>
              </ul>
              <Card.Text>
                Tropical hardwood trees are unique investment assets and not
                commodities. Their pricing is influenced by various factors
                including:
              </Card.Text>
              <ul>
                <li>
                  <strong>Market Inefficiencies:</strong> Price discrepancies
                  due to regional demand, supply chains, and barriers to entry.
                </li>
                <li>
                  <strong>Established Relationships:</strong> Long-term
                  partnerships and reputation within the industry can impact
                  price negotiations and market access.
                </li>
                <li>
                  <strong>Availability and Scarcity:</strong> As certain species
                  become rarer due to overharvesting or protection measures,
                  their value can increase dramatically.
                </li>
                <li>
                  <strong>Unique Characteristics:</strong> Each tree’s size,
                  quality, and species-specific traits (e.g., grain pattern,
                  durability) influence its market price.
                </li>
              </ul>
              <Card.Text>
                Our projections are based on over 30 years of observation and
                price evolution in the tropical hardwood region of Petén,
                Guatemala. These estimates reflect both historical data and our
                assumption that trees increase in value over time due to growth
                in both volume and price.
              </Card.Text>
              <Card.Text>
                Our research indicates that the prices for mature trees have
                roughly doubled over the past 20 years, reflecting their rising
                scarcity and increasing market demand. For verification, you can
                visit the region and consult with our forestry experts.
                Additionally, research supports these trends:
              </Card.Text>
              <ul>
                <li>
                  <strong>Blaser, J., et al. (2011).</strong> "Status of
                  Tropical Forest Management 2011." ITTO Technical Series No.
                  38, International Tropical Timber Organization, Yokohama,
                  Japan.
                </li>
                <li>
                  <strong>Brockerhoff, E. G., et al. (2017).</strong> "Tropical
                  hardwoods and their contribution to sustainable development."
                  Global Ecology and Conservation, 11, 53-70.
                </li>
              </ul>
              <Card.Text>
                These sources highlight the broader global market dynamics that
                influence tropical hardwood value, including demand shifts and
                resource constraints.{" "}
                <strong>
                  Please consult with your financial advisor before making any
                  investment decisions. The information provided here is for
                  informational and conversational purposes only and does not
                  constitute financial advice.{" "}
                </strong>
              </Card.Text>
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default DisclaimerAccordion;
