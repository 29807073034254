import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import ClientNav from "./clientComponents/clientNav";
import ClientWelcome from "./clientComponents/welcome";
import "./ClientDash.scss";
import { Route, Switch, useHistory } from "react-router-dom";
import MyTrees from "./clientComponents/myTrees";
import ClientMobileNav from "./clientComponents/clientMobileNav";
import Marketplace from "./clientComponents/marketplace/";
import FAQ from "./clientComponents/faq";
import Profile from "./clientComponents/profile";
import Projections from "./clientComponents/projections";
import { Alert, Spinner } from "react-bootstrap";
import ClientDocuments from "./clientComponents/documents";
Session.addAxiosInterceptors(axios);

export default function ClientDash({ logout }) {
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
    getData();
  }, []);
  const getUserInfo = async () => {
    await axios.get(process.env.REACT_APP_API + "/user/info").then((res) => {
      setLoading(false);
      setUserInfo(res.data);
    });
  };
  const getData = () => {
    axios.get(process.env.REACT_APP_API + "/user/documents").then((res) => {
      setDocs(res.data.documents);
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="Client-Dash">
      <div className="ClientMobileNav">
        <ClientMobileNav logout={logout} />
      </div>
      <ClientNav logout={logout} userInfo={userInfo} />
      <div className="Client-Dash-Body">
        {docs.filter(
          (e) =>
            e.client_sign === false && !["other", "transfer"].includes(e.type)
        ).length > 0 ? (
          <div className="client-dash-content">
            <Alert>
              <Alert.Heading>
                <img src="message.svg" width="30px" /> Notification
              </Alert.Heading>
              <Alert.Link href="/my-documents">
                You have 1 or more documents ready to be signed.
              </Alert.Link>
            </Alert>
          </div>
        ) : (
          ""
        )}
        <Switch>
          <Route exact path="/" userInfo={userInfo}>
            <ClientWelcome />
          </Route>
          <Route path="/my-documents">
            <ClientDocuments userInfo={userInfo} />
          </Route>
          <Route path="/marketplace">
            <Marketplace userInfo={userInfo} />
          </Route>
          <Route path="/my-trees">
            <MyTrees userInfo={userInfo} />
          </Route>
          <Route path="/projections">
            <Projections />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/profile">
            <Profile userInfo={userInfo} loading={loading} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
