import axios from "axios"
import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Offcanvas, Spinner, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Session from "supertokens-auth-react/recipe/session";
import Swal from "sweetalert2";
import './Pricing.scss'
Session.addAxiosInterceptors(axios);


export default function Pricing() {
    const [prices, setPrices] = useState([])
    const [show, setShow] = useState(false)
    const [editType, setEditType] = useState('')
    const [newPrice, setNewPrice] = useState()
    const [currentValue, setCurrentValue] = useState(0)
    useEffect(() => {
        getPricing()
    }, [])

    const handleClose = () => {
        setNewPrice()
        setShow(false)
    };

    const getPricing = () => {
        axios.get(process.env.REACT_APP_API + '/admin/pricing').then(res => {
            setPrices(res.data)
        })
    }
    const updatePrice = (type, price) => {
        axios.post(process.env.REACT_APP_API + '/admin/price/' + type, { price }).then(res => {
            if (res.data === 'updated') {
                setShow(false)
                Swal.fire({ icon: "success", text: "Updated" }).then(() => {
                    setNewPrice()
                    getPricing()
                })

            }
        })
    }
    const handleShowEdit = (type, price) => {
        setCurrentValue(price)
        setEditType(type)
        setShow(true)
    }
    const handlePriceInput = (value) => {
        let adjusted = +value

        setNewPrice(adjusted)

    }


    return (
        <div className="admin-dash-content">
            <h2>Tree Valuation</h2>
            <hr />
            <p>These should only be updated if the white paper estimates are updated.</p>
            <br />
            <Offcanvas placement='end' show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editType}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>Current Price: ${currentValue}</p>
                    <InputGroup className="mb-3">

                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control disabled value={currentValue}></Form.Control>
                    </InputGroup>
                    <p>New Price:</p>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control step='0.01' value={newPrice} onChange={e => handlePriceInput(e.target.value)} min={.50} type="number"></Form.Control>

                        </InputGroup>
                        <Button onClick={() => updatePrice(editType, newPrice)} style={{ width: '100%', marginTop: '1em' }} variant="primary">Submit Change</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Table style={{ width: '500px' }} striped bordered hover>
                <thead>
                    <tr>
                        <th>Tree Type</th>
                        <th>Price USD</th>
                        <th>Last Updated (UTC Time)</th>
                        <th></th>
                    </tr>
                    {prices.map((item, index) => (
                        <tr>
                            <td>{item.tree_type}</td>
                            <td>${item.price}</td>
                            <td>{new Date(item.date_updated).toLocaleDateString()}</td>
                            <td><Button variant="success" onClick={() => handleShowEdit(item.tree_type, item.price)}>Update</Button></td>
                        </tr>
                    ))}
                </thead>
            </Table>


        </div >
    )
}