import axios from "axios";
import React from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  ListGroup,
  Table,
} from "react-bootstrap";

const TreeDataAccordion = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const handleCancelRequest = async (transferId) => {
    // Confirm cancellation
    const confirmed = window.confirm(
      `Are you sure you want to cancel the transfer?`
    );
    if (confirmed) {
      // Add your cancellation logic here

      await axios
        .put(
          process.env.REACT_APP_API +
            "/user/cancel-transfer-request/" +
            transferId
        )
        .then((res) => {
          alert("Request has been cancelled");
          window.location.reload();
        });
    }
  };
  return (
    <Accordion>
      {data &&
        data?.map((request, index) => {
          const { requested_trees, completed, status, document_id } = request;

          return (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
                Request: Transfer ({requested_trees?.trees?.length}) trees to{" "}
                {requested_trees.newOwnerFirstName}{" "}
                {requested_trees.newOwnerLastName}
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-3">
                    <ListGroup>
                      <ListGroup.Item>
                        <strong>Email to transfer trees to:</strong>{" "}
                        {requested_trees?.newOwnerEmail}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Status:</strong>{" "}
                        <Badge bg={status === "new" ? "success" : "warning"}>
                          {status}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Completed:</strong>{" "}
                        <Badge bg={completed ? "success" : "danger"}>
                          {completed ? "Yes" : "No"}
                        </Badge>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  {request.status !== "Complete" && (
                    <Button
                      variant="danger"
                      onClick={() => handleCancelRequest(request.transfer_id)}
                    >
                      Cancel Request
                    </Button>
                  )}
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Tree ID</th>
                      <th>Tree Type</th>
                      <th>Date Planted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requested_trees?.trees?.map((tree) => (
                      <tr key={tree.tree_id}>
                        <td>{tree.tree_id}</td>
                        <td>{tree.tree_type}</td>
                        <td>{tree.date_planted}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
};

export default TreeDataAccordion;
