import { useState } from "react"
import { useEffect } from "react"
import { Button, Dropdown, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom"
import AddListings from "./addListings";
import CurrentListings from "./currentListings";
import RemoveListings from "./removeListings";


export default function MyListings({trees, getTrees}) {
    const [listedTrees, setListedTrees] = useState([])
    const [ownedTrees, setOwnedTrees] = useState([])
    const history = useHistory()
    useEffect(() => {
        let listed = trees.filter(e => e.status === 'listed')
        let owned = trees.filter(e => e.status === 'owned')
        let pending = trees.filter(e => e.status === 'pending')
        setListedTrees(listed)
        setOwnedTrees(owned)
    }, [])


    return (
        <div className="my-listings">
            <hr />
            <div className="listing-actions">

                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => history.push('/my-trees/listings/add')}>Add Trees To marketplace</Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push('/my-trees/listings/remove')}>Remove Trees From Marketplace</Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push('/my-trees/listings/')}>View Current Listings</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
           
                {/* <Button variant="success">Put Trees For Sale On The Marketplace</Button> */}
            </div>
            <hr />


            <Switch>
                <Route exact path="/my-trees/listings/">
                   <CurrentListings listedTrees={listedTrees}/>
                </Route>
                <Route path="/my-trees/listings/add">
                    <AddListings ownedTrees={ownedTrees} getTrees={getTrees}/>
                </Route>
                <Route path="/my-trees/listings/remove">
                    <RemoveListings listedTrees={listedTrees} getTrees={getTrees}/>
                </Route>
            </Switch>
        </div>
    )
}