import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  Card,
  Button,
  ListGroup,
  Modal,
  Form,
  Alert,
  Badge,
} from "react-bootstrap";

export default function TransferRequests() {
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [documentLinkId, setDocumentLinkId] = useState("");
  const [newOwnerEmail, setNewOwnerEmail] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API + "/admin/transfers"
      );
      console.log(res.data);
      setData(res.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleCompleteTransfer = (request) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDocumentLinkId("");
    setNewOwnerEmail("");
    setErrors({});
  };

  const validateForm = () => {
    let formErrors = {};

    if (!documentLinkId.trim()) {
      formErrors.documentLinkId = "Document Link ID is required.";
    }

    if (!newOwnerEmail.trim()) {
      formErrors.newOwnerEmail = "New Owner Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(newOwnerEmail)) {
      formErrors.newOwnerEmail = "Invalid email format.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // New function to call the API
  const completeTransferRequest = async () => {
    try {
      const payload = {
        trees: selectedRequest.requested_trees.trees.map((e) => e.tree_id),
        transfer_id: selectedRequest.transfer_id,
        new_owner_email: newOwnerEmail,
        former_owner_id: selectedRequest.owner_id,
        document_link: documentLinkId,
      };
      await axios
        .post(process.env.REACT_APP_API + "/admin/complete-transfer", payload)
        .then((res) => {
          console.log(res);
          alert("Success");
        })
        .catch(() => {
          alert("Unable to transfer");
        })
        .finally(() => {
          getData();
        });
      // console.log("Transfer completed successfully:", response.data);
      // alert("Transfer completed successfully!");

      // Optionally, refresh the data
      getData();

      handleModalClose();
    } catch (error) {
      console.error("Error completing transfer:", error);
      alert("There was an error processing the transfer.");
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log(
        `Document Link ID: ${documentLinkId}, New Owner Email: ${newOwnerEmail}, Transfer Request ID: ${selectedRequest.transfer_id}`
      );
      completeTransferRequest(); // Call the new function
    }
  };

  const copyToClipboard = (trees) => {
    const treeDetails = trees
      .map((tree) => `${tree.tree_id} - ${tree.tree_type.toUpperCase()}`)
      .join("\n");

    navigator.clipboard
      .writeText(treeDetails)
      .then(() => {
        alert(
          "Tree details copied to clipboard! You can paste these in the new document."
        );
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="admin-dash-content">
      <h2>Transfer Requests</h2>
      <hr />
      <Accordion activeKey={activeKey}>
        {data?.map((request, index) => (
          <>
            <Card key={request.transfer_id}>
              <Card.Header>
                <Accordion.Button
                  as={Button}
                  variant="link"
                  eventKey={index.toString()}
                  onClick={() => handleToggle(index.toString())}
                >
                  Transfer Request ({request.first_name} {request.last_name}){" "}
                  <Badge
                    style={{ marginLeft: "1rem" }}
                    bg={request.status !== "Complete" ? "primary" : "success"}
                  >
                    {request.status}
                  </Badge>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body>
                  <h5>Request Details:</h5>
                  <p>
                    <strong>New Owner:</strong>{" "}
                    {request.requested_trees.newOwnerFirstName}{" "}
                    {request.requested_trees.newOwnerLastName} (
                    {request.requested_trees.newOwnerEmail})
                  </p>
                  <p>
                    <strong>Date Created:</strong>{" "}
                    {new Date(
                      request.requested_trees.created_date
                    ).toLocaleString()}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    <Badge
                      bg={request.status !== "Complete" ? "primary" : "success"}
                    >
                      {request.status}
                    </Badge>
                  </p>
                  <Button
                    variant="primary"
                    onClick={() =>
                      copyToClipboard(request.requested_trees.trees)
                    }
                  >
                    Copy Tree List
                  </Button>
                  <br />
                  <br />
                  <ListGroup>
                    {request.requested_trees.trees.map((tree) => (
                      <ListGroup.Item key={tree.treenumberid}>
                        <strong>{tree.tree_id}</strong> -{" "}
                        {tree.tree_type.toUpperCase()}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <div className="mt-3">
                    {request.status !== "Complete" && (
                      <Button
                        variant="success"
                        disabled={request.completed}
                        onClick={() => handleCompleteTransfer(request)}
                      >
                        Complete Transfer
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <br />
          </>
        ))}
      </Accordion>

      <Modal show={showModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Complete Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Alert>
              <Alert.Heading>IMPORTANT</Alert.Heading>
              If the new owner doesn't have a marketplace account, please make
              sure you create one first, otherwise their email won't be accepted
              by the system.
            </Alert>
            <Form.Group controlId="documentLinkId">
              <Form.Label>Document Link ID</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter document link ID"
                value={documentLinkId}
                isInvalid={!!errors.documentLinkId}
                onChange={(e) => setDocumentLinkId(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.documentLinkId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="newOwnerEmail" className="mt-3">
              <Form.Label>New Owner Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter new owner email"
                value={newOwnerEmail}
                isInvalid={!!errors.newOwnerEmail}
                onChange={(e) => setNewOwnerEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newOwnerEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
