import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Owner.scss";
import {
  Accordion,
  Alert,
  Button,
  Card,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Route, Switch, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import AssignNew from "./assignNew";
import OwnerTreesTable from "./ownerTreesTable";
import RemoveTree from "./removeTree";
import UpdateBill from "./updateBill";
import Swal from "sweetalert2";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import NewBOS from "../sales/components/newBOS";
import convertDoctype from "../../../utilities/convertDocType";
import formatTimestamp from "../../../utilities/formatTimestamp";
Session.addAxiosInterceptors(axios);

export default function Owner() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserinfo] = useState("");
  const [trees, setTrees] = useState("");
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/admin/user/" + params.id + "/info")
      .then((res) => {
        setUserinfo(res.data[0]);
      });
    await axios
      .get(process.env.REACT_APP_API + "/admin/user/" + params.id + "/trees")
      .then((res) => {
        setTrees(res.data);
      });
    setLoading(false);
  };

  const returnDocumentList = () => {
    const userIncomplete = userInfo.documents.filter(
      (e) => e.client_sign === false && !["other", "transfer"].includes(e.type)
    );
    const adminIncomplete = userInfo.documents.filter(
      (e) =>
        e.client_sign === true &&
        e.admin_sign === false &&
        !["other", "transfer"].includes(e.type)
    );
    const complete = userInfo.documents.filter(
      (e) =>
        (e.client_sign === true && e.admin_sign === true) ||
        ["other", "transfer"].includes(e.type)
    );

    return (
      <>
        {" "}
        {userIncomplete.length > 0 ? (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Waiting For Client To Sign</Accordion.Header>
              <Accordion.Body>
                {userIncomplete.map((doc) => (
                  <Alert variant="warning" text="dar">
                    {convertDoctype(doc.type, doc)} | Created:{" "}
                    {formatTimestamp(doc.date_created)}{" "}
                    <Button
                      onClick={() =>
                        history.push(
                          "/document/" + doc.document_id + "/" + doc.type
                        )
                      }
                    >
                      View
                    </Button>
                  </Alert>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          ""
        )}
        {adminIncomplete.length > 0 ? (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Waiting For Admin To Sign</Accordion.Header>
              <Accordion.Body>
                {adminIncomplete.map((doc) => (
                  <Alert variant="danger" text="dar">
                    {convertDoctype(doc.type, doc)} | Created:{" "}
                    {formatTimestamp(doc.date_created)}{" "}
                    <Button
                      onClick={() =>
                        history.push(
                          "/document/" + doc.document_id + "/" + doc.type
                        )
                      }
                    >
                      View
                    </Button>
                  </Alert>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          ""
        )}
        {complete.length > 0 ? (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Completed Documents</Accordion.Header>
              <Accordion.Body>
                {complete.map((doc) => (
                  <Alert variant="success" text="dar">
                    {convertDoctype(doc.type, doc)} | Created:{" "}
                    {formatTimestamp(doc.date_created)}{" "}
                    {
                      <Button
                        onClick={() =>
                          ["other", "transfer"].includes(doc.type)
                            ? window.open(doc.link, "_blank")
                            : history.push(
                                "/document/" + doc.document_id + "/" + doc.type
                              )
                        }
                      >
                        View
                      </Button>
                    }
                  </Alert>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          ""
        )}
      </>
    );
  };

  const setTestAccount = () => {
    Swal.fire({
      icon: "info",
      title: "Are you sure?",
      text: "You will be logged out, then you will login with test@goodalways.com which will give you access to this users account.",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(process.env.REACT_APP_API + "/admin/user/test/" + params.id)
          .then(() => {
            signOut().then(() => {
              signOut();
              document.cookie.split(";").forEach((c) => {
                document.cookie = c
                  .replace(/^ +/, "")
                  .replace(
                    /=.*/,
                    "=;expires=" + new Date().toUTCString() + ";path=/"
                  );
              });
              window.location.reload();
            });
          });
      } else {
        return;
      }
    });
  };

  if (loading) {
    return (
      <div className="admin-dash-content">
        <Spinner animation="border" variant="success"></Spinner> loading ...
      </div>
    );
  } else {
    return (
      <div className="admin-dash-content">
        <div className="owner">
          <div className="owner-top-bar">
            <Card style={{ width: "500px" }}>
              <Card.Header>
                {userInfo.first_name + " " + userInfo.last_name} |{" "}
                {userInfo.owner_id}
              </Card.Header>
              <Card.Body>
                <Card.Title>Contact Info</Card.Title>
                <Card.Text>
                  <ListGroup>
                    <ListGroup.Item>
                      <img
                        id="icon"
                        src="/email.svg"
                        width="25px"
                        alt="email"
                      />{" "}
                      {userInfo.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <img
                        id="icon"
                        src="/phone.svg"
                        width="25px"
                        alt="phone"
                      />{" "}
                      {userInfo.phone}
                    </ListGroup.Item>
                  </ListGroup>
                  <hr />
                  <ListGroup>
                    {userInfo.bill_of_sale &&
                      userInfo.bill_of_sale !== "none" && (
                        <ListGroup.Item>
                          <img
                            id="icon"
                            src="/file.svg"
                            width="25px"
                            alt="file"
                          />{" "}
                          {userInfo.bill_of_sale === "none" ? (
                            "No bill of sale attached"
                          ) : (
                            <a
                              href={userInfo.bill_of_sale}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Bill Of Sale
                            </a>
                          )}
                        </ListGroup.Item>
                      )}
                  </ListGroup>
                </Card.Text>
                {returnDocumentList()}
              </Card.Body>
            </Card>
            <Card style={{ width: "500px" }}>
              <Card.Header>Actions</Card.Header>
              <Card.Body>
                <Card.Text>
                  <ListGroup>
                    <ListGroup.Item
                      onClick={() =>
                        history.push("/owner/" + userInfo.owner_id)
                      }
                      action
                      variant="primary"
                    >
                      <img
                        id="icon"
                        src="/table.svg"
                        width="25px"
                        alt="table"
                      />{" "}
                      View Trees Assigned To This Account
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => window.location.reload()}
                      action
                      variant="primary"
                    >
                      <img
                        id="icon"
                        src="/refresh.svg"
                        width="25px"
                        alt="refresh"
                      />{" "}
                      Refresh Data
                    </ListGroup.Item>
                    {/* <ListGroup.Item onClick={() => history.push('/owner/' + userInfo.owner_id + '/trees/add')} action variant="success">
                                            <img id="icon" src="/add.svg" width="25px" /> Assign New Trees To This Account
                                        </ListGroup.Item> */}
                    <ListGroup.Item
                      onClick={() =>
                        history.push("/owner/" + userInfo.owner_id + "/new-bos")
                      }
                      action
                      variant="success"
                    >
                      <img id="icon" src="/add.svg" width="25px" alt="add" />{" "}
                      Create New Bill of Sale
                    </ListGroup.Item>
                    {/* <ListGroup.Item onClick={() => history.push('/owner/' + userInfo.owner_id + '/bill-of-sale')} action variant="success">
                                            <img id="icon" src="/file.svg" width="25px" /> Update Bill Of Sale Link
                                        </ListGroup.Item> */}
                    <ListGroup.Item
                      onClick={() =>
                        history.push(
                          "/owner/" + userInfo.owner_id + "/trees/remove"
                        )
                      }
                      action
                      variant="danger"
                    >
                      <img
                        id="icon"
                        src="/remove.svg"
                        width="25px"
                        alt="remove"
                      />{" "}
                      Remove Trees From This Account
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => setTestAccount()}
                      action
                      variant="info"
                    >
                      <img
                        id="icon"
                        src="/key-icon.svg"
                        width="25px"
                        alt="login"
                      />{" "}
                      Login As This User
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <Switch>
            <Route exact path="/owner/:id">
              <OwnerTreesTable trees={trees} />
            </Route>
            <Route path="/owner/:id/trees/add">
              <AssignNew userInfo={userInfo} />
            </Route>
            <Route path="/owner/:id/trees/remove">
              <RemoveTree />
            </Route>
            <Route path="/owner/:id/bill-of-sale">
              <UpdateBill userInfo={userInfo} />
            </Route>
            <Route path="/owner/:id/new-bos">
              <hr />
              <NewBOS name={userInfo.first_name + " " + userInfo.last_name} />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}
