import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Spinner, Table, Badge, Form, ListGroup, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import './selectTrees.scss'

export default function SelectTrees({ handleReturn, setShowSelect, showSelect, title }) {

    const [trees, setTrees] = useState([])
    const [loading, setLoading] = useState(true)
    const [sector, setSector] = useState(0)
    const [search, setSearch] = useState('')
    const [line, setLine] = useState('')
    const [results, setResults] = useState([])
    const history = useHistory()
    const [selected, setSelected] = useState([])
    const [previous, setPrevious] = useState(0)
    const [selectOwned, setSelectOwn] = useState(false)

    useEffect(() => {
        getTrees()
    }, [])

    const getTrees = () => {
        axios.get(process.env.REACT_APP_API + '/admin/trees/all').then((res) => {
            setTrees(res.data)
            setLoading(false)
        })
    }
    const handleAccept = () => {
        handleReturn(selected)
        setShowSelect(false)
    }

    const handleSelect = (e, index) => {
        let copySelected = selected
        if (e.nativeEvent.shiftKey) {
            if (e.target.checked) {

                let list = results.slice(previous, index + 1)
                Promise.all(list.map((item, index) => {
                    if (!item.owner_id) {
                        if (!item.reserved_for) {
                            if (selected.indexOf(item) === -1) {
                                copySelected.push(item)
                            }
                        }
                    }
                })).then(() => {
                    setSelected([...copySelected])
                })
            } else {
                copySelected = copySelected.filter(e => e !== results[index])
                setSelected([...copySelected])
            }
        } else {
            setPrevious(index)
            if (e.target.checked) {
                copySelected.push(results[index])
                setSelected([...copySelected])
            } else {
                copySelected = copySelected.filter(e => e.tree_id !== results[index].tree_id)
                setSelected([...copySelected])
            }
        }

    }
    const handleRemove = (id) => {
        let copySelected = selected.filter(e => e.tree_id !== id)

        setSelected([...copySelected])
    }
    const handleFilter = (items) => {

        let filterBySector = sector !== null ? items.filter(e => +e.sector === +sector) : items
        let adjustLine = 'L' + line
        let filterByLine = line !== '' ? filterBySector.filter(e => e.line == adjustLine) : filterBySector
        return filterByLine
    }
    const handleSetResults = async () => {
        let data = await handleFilter(trees)
        setResults(data)
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (

        <Modal show={showSelect} onHide={() => setShowSelect(false)} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '1em' }}>
                {loading ? <Spinner animation="border" variant="success" ></Spinner>
                    :
                    <div className="">
                        <div style={{ display: 'flex' }}>
                            <div className='modal-half-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="filter-bar">
                                    <div>
                                        <Form.Label htmlFor="inline-radio-1">Select Sector</Form.Label>
                                        <div key={`inline-radio`} className="mb-3">
                                            <Form.Check onClick={() => setSector(null)}
                                                inline
                                                label="All"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-1`}
                                            />
                                            <Form.Check onClick={() => setSector(1)}
                                                inline
                                                label="1"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-1`}
                                            />
                                            <Form.Check onClick={() => setSector(2)}
                                                inline
                                                label="2"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-2`}
                                            />
                                            <Form.Check onClick={() => setSector(3)}
                                                inline
                                                label="3"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-3`}
                                            />
                                                <Form.Check onClick={() => setSector(4)}
                                                inline
                                                label="4"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-3`}
                                            />
                                        </div>
                                    </div>
                                    <div>

                                        <Form.Label>Enter Line (1-167)</Form.Label>
                                        <Form.Control onChange={e => setLine(e.target.value)} type="number" value={line} />

                                    </div>
                                    <div>

                                        <Button variant="success" onClick={() => handleSetResults()}>Search</Button>
                                    </div>

                                </div>
                                <br />
                                <p>{results.length}  Results</p>

                                <div className="list-scroll">

                                    {
                                        results.length < 1 ? <p>Search for trees using the filters above.</p> :
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>ID</th>
                                                        <th>Type / Date</th>
                                                        <th>Owner</th>

                                                        <th>Current Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {results.map((tree, index) => (
                                                        <tr key={tree + index}>
                                                            <td><input type="checkbox" disabled={tree.owner_id || tree.reserved_for ? true : false} style={{ display: `${tree.owner_id ? 'none' : ''}` }} onChange={e => handleSelect(e, index)} checked={selected.find(e => e.tree_id === tree.tree_id) ? true : false} /> </td>
                                                            <td>{tree.tree_id}</td>
                                                            <td>{tree.tree_type} <br /> {tree.date_planted}</td>
                                                            <td>{tree.owner_id ? <Badge variant="success">{tree.first_name} {tree.last_name}</Badge> : tree.reserved_for ? <Badge bg="warning" text="dark" onClick={() => alert(tree.document)}>Reserved: {tree.reserved_for}</Badge> : 'No Owner'}</td>
                                                            <td>${tree.current_value}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </div>
                            <div className="modal-half-column">
                                <ListGroup>

                                    <ListGroup.Item variant="secondary">
                                        ({selected.length}) trees selected
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <p>
                                            Total value: {formatter.format(selected.reduce((previous, current) => previous + +current.current_value, 0))}
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <Button variant="warning" onClick={() => setSelected([])}>Clear</Button>
                                            <Button style={{ marginLeft: '1em' }} variant="primary" onClick={() => handleAccept()}>Accept</Button>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <br />
                                <br />
                                <br />
                                <div className="list-scroll">
                                    <ListGroup>
                                        {selected.map((tree, index) => (
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center" key={tree + index}>
                                                <div className="item-25">{tree.tree_id}</div>
                                                <div className="item-25">
                                                    {tree.tree_type}
                                                </div>
                                                <div className="item-25">
                                                    <Badge bg="primary" pill>
                                                        {tree.current_value}
                                                    </Badge>
                                                </div>
                                                <div className="item-25">

                                                    <Badge bg="danger" onClick={() => handleRemove(tree.tree_id)} style={{ cursor: 'pointer' }}>X</Badge>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>

                            </div>

                        </div>

                    </div>

                }
            </Modal.Body>
        </Modal>

    )
}