import axios from "axios";
import { Button, Card, Form, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";


export default function NewManagement() {

    const history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const owner_id = formData.get('owner_id')
        try {
            await axios.get(process.env.REACT_APP_API + '/admin/documents/special-mgmt/' + owner_id)
            Swal.fire({ icon: 'success', title: 'success' })
            history.push('/owner/' + owner_id)
        } catch {
            Swal.fire({ icon: 'error', title: 'ERROR' })
        }
    }

    return (
        <Form onSubmit={e => handleSubmit(e)}>
            <Card className='m-4'>
                <Card.Header>
                    Create A New Management Agreement
                </Card.Header>
                <Card.Body>
                    <em>Paste the Owner ID of the user you want to create a new MGMT doc for.</em>
                    <br />
                    <hr />
                    <FormControl label='Owner ID' id='owner_id' required name='owner_id' placeholder="Owner ID" type='text' />
                </Card.Body>
                <Card.Footer>
                    <Button type='submit' variant="success">Submit</Button>
                </Card.Footer>
            </Card>
        </Form>
    )
}