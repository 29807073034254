import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed
import CreateNewBlankUser from './createNewBlankUser';
import NewManagement from '../developer/developerComponents/newMgmt/newManagement';

const AdminTools = () => {
    return (
        <div className="admin-dash-content">
            <h2>Admin Tools</h2>
            <hr />
            <Row>
                <Col md={12}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Trees and Prices:</Card.Title>
                            <Link to="/pricing" className="btn btn-link">
                                <Button>
                                    Manage Tree Valuation
                                </Button>
                            </Link>
                            <br />
                            <Link to="/trees" className="btn btn-link">
                                <Button>
                                    Tree Search
                                </Button>
                            </Link>
                        </Card.Body>
                    </Card>

                </Col>

                <Col md={6}>
                    <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Create New User</Accordion.Header>
                            <Accordion.Body>
                                <CreateNewBlankUser />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Create New Management Document</Accordion.Header>
                            <Accordion.Body>
                                <NewManagement />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </div >
    );
};

export default AdminTools;
