import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import "./transfer.scss";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import Swal from "sweetalert2";
import TreeDataAccordion from "./TreeDataAccordion";

Session.addAxiosInterceptors(axios);

export default function CreateTransfer({ ownedTrees, getTrees }) {
  const [addList, setAddList] = useState([]);
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showTreeSelect, setShowTreeSelect] = useState(false);
  const [previous, setPrevious] = useState(0);
  const [newRequest, setNewRequest] = useState(false);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getRequests();
  }, []);
  const getRequests = async () => {
    setLoading(true);
    await axios
      .get(process.env.REACT_APP_API + "/user/transfer-requests")
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          console.log("setting data");
          setRequests(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleChange = (e, id) => {
    if (e.target.checked) {
      let getItem = ownedTrees.findIndex((test) => test.tree_id === id);
      handleAdd(ownedTrees[getItem]);
    } else {
      handleRemove(id);
    }
  };

  const handleSelect = (e, index) => {
    let newAddList = [...addList];
    if (e.nativeEvent.shiftKey && previous !== null) {
      const start = Math.min(previous, index);
      const end = Math.max(previous, index);
      const newItems = ownedTrees.slice(start, end + 1);

      if (e.target.checked) {
        // Add new items, avoiding duplicates
        const newIds = new Set(newAddList.map((item) => item.tree_id));
        newItems.forEach((item) => {
          if (!newIds.has(item.tree_id)) {
            newAddList.push(item);
          }
        });
      } else {
        // Remove items in the range
        const idsToRemove = new Set(newItems.map((item) => item.tree_id));
        newAddList = newAddList.filter(
          (item) => !idsToRemove.has(item.tree_id)
        );
      }
    } else {
      setPrevious(index); // Update last interacted index only if not using shift
      if (e.target.checked) {
        newAddList.push(ownedTrees[index]);
      } else {
        newAddList = newAddList.filter(
          (item) => item.tree_id !== ownedTrees[index].tree_id
        );
      }
    }
    setAddList(newAddList);
  };

  const handleAdd = (value) => {
    setAddList([...addList, value]);
  };
  const handleRemove = (value) => {
    let copy = addList;
    copy.splice(
      copy.findIndex((e) => e.tree_id === value),
      1
    );
    setAddList([...addList]);
  };
  const handleConfirm = () => {
    axios
      .post(process.env.REACT_APP_API + "/user/transfer-request", {
        trees: addList,
        newOwnerEmail: email,
        newOwnerFirstName: firstName,
        newOwnerLastName: lastName,
      })
      .then((res) => {
        res.data.message === "success"
          ? Swal.fire({
              icon: "success",
              text: "Submitted Request",
              timer: 2000,
              showConfirmButton: false,
            }).then(() => {
              getTrees();
              setNewRequest(false);
              getRequests();
              setShow(false);
            })
          : Swal.fire({
              icon: "error",
              text: res.data,
              timer: 2000,
              showConfirmButton: false,
            }).then(() => {
              setShow(false);
            });
      });
  };
  const inProgress = requests?.filter((e) => e.status !== "Complete") ?? [];
  const completedRequests =
    requests?.filter((e) => e.status === "Complete") ?? [];
  return (
    <>
      <br />
      {
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setNewRequest(false)}>
              View Current Requests
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setNewRequest(true)}>
              Create Transfer Request
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      }
      {!newRequest && (
        <div>
          <br />
          <Alert variant="primary">Transfer Requests:</Alert>
          {inProgress?.length < 1 && (
            <p>You have no transfer requests in progress.</p>
          )}
          {inProgress?.length > 0 && (
            <TreeDataAccordion data={inProgress} />
          )}
          {completedRequests?.length > 0 && (
            <>
              <br />
              <Alert variant="success">
                Completed Transfers:
              </Alert>
            </>
          )}
          {completedRequests?.length > 0 && (
            <TreeDataAccordion data={completedRequests} />
          )}
        </div>
      )}
      {newRequest && (
        <div className="create-transfer">
          <br />
          <Alert variant="primary">Transfer tree ownership:</Alert>
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong>
                Please confirm you want to transfer ({addList.length}) trees.
              </strong>
              <p>
                A request will be sent to a Las Azucenas Admin who will prepare
                the relevant documents for you to transfer your trees.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleConfirm()}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
          <Form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              console.log(e);
              setShowTreeSelect(true);
            }}
          >
            <Row>
              <Col>
                Please enter the first name, last name, and email of the
                individual you are transferring your trees to.
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button variant="primary" type="submit">
                  Confirm
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
          {showTreeSelect && (
            <>
              <Alert variant="secondary">
                <strong>Select Trees by ID to transfer:</strong>
                <hr />
                <p>
                  After selecting your trees, scroll to the bottom of the page
                  to find the submit button.
                </p>
                <p>
                  Tip: If you are selecting multiple trees in a row, hold down
                  the shift key to select a range.
                </p>
              </Alert>

              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Select by Tree ID</th>
                    <th>Type</th>
                    <th>Date Planted</th>
                    <th>Tree Age</th>
                    <th>Est. Value</th>
                  </tr>
                </thead>
                <tbody>
                  {ownedTrees?.map((tree, index) => (
                    <tr key={index + "check"}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleSelect(e, index)}
                          checked={
                            addList.find((e) => e.tree_id === tree.tree_id)
                              ? true
                              : false
                          }
                        />
                        <label style={{ marginLeft: "1em" }}>
                          {tree.tree_id}
                        </label>
                      </td>
                      <td>{tree.tree_type}</td>
                      <td>{tree.date_planted}</td>
                      <td>
                        {Math.floor(tree.months / 12).toFixed(0) +
                          " years " +
                          (tree.months % 12) +
                          " month(s)"}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>$</div>
                        {(+tree.current_value).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Alert variant="secondary">
                <strong>Review your selections and press submit:</strong>
                <p>({addList.length}) trees selected.</p>
                <hr />
                <Button
                  onClick={() => setShow(true)}
                  disabled={addList.length > 0 ? false : true}
                >
                  Submit
                </Button>
              </Alert>
            </>
          )}
        </div>
      )}
    </>
  );
}
